import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Form, Spinner } from "react-bootstrap";
import Knob from "./Knob";
import NumericInput from "react-numeric-input";
import SearchBox from "./SearchBox";
import useAuth from "../../../hooks/useAuth";
import { globalConfig } from "../../../config";
import { getDistance, middle, radiusZoomLevel } from "../../../utils/staticMethods";
import * as queryString from "query-string";
import { useLocation } from "react-router-dom";

let overlay;
let selectShape = null;
let drawingManager = null;

// floorplan edit

let Streetview = ({ img, onCenterChanged, curFloor }, ref) => {

  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [rotate, setRotate] = useState(0);
  const [opacity, setOpacity] = useState(100);
  const [position, setPosition] = useState();
  const [position1, setPosition1] = useState();
  const [altitude, setAltitude] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation()
  const { id } = queryString.parse(location.search);
  const [drawing, setDrawing] = useState(false);
  const rotateRef = useRef(rotate);
  const searchBox = useRef();
  const { user } = useAuth();

  useEffect(() => {
    if(id) setLoading(true)
  }, [id])
  const getMapOptions = (maps) => {
    if(mapInstance && mapApi) return;
    return {
      mapId: "90f87356969d889c",
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      heading: 0,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    };
  };

  useImperativeHandle(ref, () => ({
    onGetPath: () => {
      let obj = onSave();
      return obj;
    }
  }));

  useEffect(() => {
    if(overlay) {
      overlay.setMap(null);
      overlay = null;
    }
    if (drawingManager) {
      drawingManager.setDrawingMode(null);
      drawingManager.setMap(null);
      drawingManager = null;
    }
    if (selectShape) {
      selectShape.setMap(null);
      selectShape = null;
    }

    return () => {
      if (overlay) {
        overlay.setMap(null);
        overlay = null;
      }
      if (drawingManager) {
        drawingManager.setDrawingMode(null);
        drawingManager.setMap(null);
        drawingManager = null;
      }
      if (selectShape) {
        selectShape.setMap(null);
        selectShape = null;
      }
    };
  }, []);

  const onSave = () => {
    if (selectShape) {
      let v = selectShape.getPath();
      let bounds = new mapApi.LatLngBounds();
      let pathArray = [];
      for (let i = 0; i < v.getLength(); i++) {
        let xy = v.getAt(i);
        let filter = pathArray.filter(item => {
          let spit = item.split(",");
          return spit[0] === xy.lng().toString() && spit[1] === xy.lat().toString();
        });
        if (filter.length === 0) {
          pathArray.push(xy.lng() + "," + xy.lat());
          bounds.extend(new mapApi.LatLng(xy.lat(), xy.lng()));
        }
      }
      let end = pathArray[0].split(",");
      bounds.extend(new mapApi.LatLng(end[1], end[0]));
      return {
        position,
        position1,
        altitude,
        rotate,
        pathArray
      };
    }
    return {
      position,
      position1,
      altitude,
      rotate
    };
  };

  const initPolygon = () => {
    if (selectShape) {
      selectShape.setMap(null);
      selectShape = null;
    }
    let firstItem = curFloor.polygon[0].split(",");
    let minLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      maxLat = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      minLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) },
      maxLng = { lat: Number(firstItem[1]), lng: Number(firstItem[0]) };
    let arr = curFloor.polygon.map(item => {
      let me = item.split(",");
      let lng = Number(me[0]);
      let lat = Number(me[1]);
      if (lat > maxLat.lat) maxLat = { lat, lng };
      if (lat < minLat.lat) minLat = { lat, lng };
      if (lng > maxLng.lng) maxLng = { lat, lng };
      if (lng < minLng.lng) minLng = { lat, lng };
      return { lng, lat };
    });

    selectShape = new mapApi.Polygon({
      paths: arr,
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#242424",
      fillOpacity: 0.3,
      editable: false,
      draggable: false,
      type: "polygon",
      zIndex: 99999,
    });
    selectShape.setMap(mapInstance);

  };

  const apiHasLoaded = (map, maps) => {
    if (!map || !maps) return;
    if (mapInstance && mapApi) return;
    onCenterChanged && onCenterChanged(map.getCenter());
    window.myMap = map;
    map.addListener("center_changed", () => {
      onCenterChanged && onCenterChanged(map.getCenter());
    });
    setInstance(map);
    setApi(maps);
  };

  useEffect(() => {
    if (overlay) overlay.div.style.opacity = opacity / 100;
  }, [opacity]);

  useEffect(() => {
    if (!mapInstance) return;
    rotateRef.current = rotate;
    if (overlay && overlay.div) {
      let pos = overlay.getProjection().fromLatLngToDivPixel(overlay.position);
      let pos1 = { x: pos.x + overlay.div.clientWidth, y: pos.y + overlay.div.clientHeight };
      let sw = overlay.getProjection().fromDivPixelToLatLng(pos1);
      overlay.position1 = sw;
      overlay.rotating = true;
    }
    mapInstance.setHeading(360 - rotateRef.current);
  }, [rotate]);

  const changeOverlayPosition = (positionName, coordinateName, value) => {
    if (overlay && mapApi && positionName === "position") {
      if (coordinateName === "lat") {
        overlay[positionName] = new mapApi.LatLng(Number(value), overlay[positionName].lng());
      } else if (coordinateName === "lng") {
        overlay[positionName] = new mapApi.LatLng(overlay[positionName].lat(), Number(value));
      }
      let pos = overlay.getProjection().fromLatLngToDivPixel(overlay.position);
      let x = pos.x + overlay.div.clientWidth;
      let y = pos.y + overlay.div.clientHeight;
      let bottom = overlay.getProjection().fromDivPixelToLatLng({ x, y });
      overlay.position1 = bottom;
      overlay.draw();
    } else if (overlay && mapApi && positionName === "position1") {
      if (coordinateName === "lat") {
        overlay[positionName] = new mapApi.LatLng(Number(value), overlay[positionName].lng());
      } else if (coordinateName === "lng") {
        overlay[positionName] = new mapApi.LatLng(overlay[positionName].lat(), Number(value));
      }
      let pos = overlay.getProjection().fromLatLngToDivPixel(overlay.position1);
      let x = pos.x - overlay.div.clientWidth;
      let y = pos.y - overlay.div.clientHeight;
      let top = overlay.getProjection().fromDivPixelToLatLng({ x, y });
      overlay.position = top;
      overlay.draw();
    }
  };

  const addImage = () => {
    let northWest = mapInstance.getCenter();
    if(overlay) {
      overlay.setMap(null)
      overlay = null
    }

    overlay = new mapApi.OverlayView();
    overlay.div = null;
    overlay.image = img;
    overlay.position = curFloor ? new mapApi.LatLng(curFloor.northWestLatitude, curFloor.northWestLongitude) : northWest;
    overlay.position1 = (curFloor?.southEastLatitude && curFloor.southEastLongitude) ? new mapApi.LatLng(curFloor.southEastLatitude, curFloor.southEastLongitude) : null;
    overlay.draw = function(drawing) {
      if (mapInstance.getHeading() !== (360 - rotateRef.current) && mapInstance.getHeading() !== rotateRef.current) {
        mapInstance.setHeading(360 - rotateRef.current);
        this.rotating = true;
      }
      const overlayProjection = this.getProjection();
      if (this.div && overlayProjection) {
        let pos1 = overlayProjection.fromLatLngToDivPixel(this.position);
        this.div.style.left = pos1.x + "px";
        this.div.style.top = pos1.y + "px";
        if (this.position1 && this.div.style.cursor !== "move" && this.rotating === false && drawing) {
          const se = overlayProjection.fromLatLngToDivPixel(this.position1);
          this.div.style.width = se.x - pos1.x + "px";
          this.div.style.height = "fit-content";
        }
        this.rotating = false;
      }
      setPosition(this.position);
      setPosition1(this.position1);
    };
    overlay.onRemove = function() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
      }
    };
    overlay.onAdd = function() {
      let that = this;
      this.div = document.createElement("div");
      this.div.id = "whole-container";
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "0px";
      this.div.style.visibility = "visible";
      this.div.style.position = "absolute";
      this.div.style.height = "fit-content";
      this.div.style.cursor = "default";
      const img = document.createElement("img");
      let arr = [];
      this.div.draggable = false;
      const leftTop = document.createElement("span");
      const rightTop = document.createElement("span");
      const leftBot = document.createElement("span");
      const rightBot = document.createElement("span");
      leftTop.classList = "leftTop move-sqare";
      rightTop.classList = "rightTop move-sqare";
      leftBot.classList = "leftBot move-sqare";
      rightBot.classList = "rightBot move-sqare";

      this.div.appendChild(leftBot);
      this.div.appendChild(leftTop);
      this.div.appendChild(rightTop);
      this.div.appendChild(rightBot);
      arr = [leftTop, rightTop, leftBot, rightBot];
      this.div.draggable = true;


      let dragType = null;

      img.src = this.image;
      img.style.width = "100%";
      img.style.height = "100%";
      this.div.appendChild(img);

      arr.forEach(item => {
        mapApi.event.addDomListener(item, "mousedown", function(e) {
          e.stopPropagation();
          that.isScaling = true;
          dragType = e.target.className;
          mapInstance.set("draggable", false);
          that.div.draggable = false;
          that.set("origin", e);
          that.scaleHandler = mapApi.event.addDomListener(mapInstance.getDiv(),
            "mousemove",
            function(e) {
              if (!that.isScaling) return;
              let origin = that.get("origin");
              if (dragType.indexOf("rightBot") > -1) {
                let left = e.clientX - origin.clientX;
                that.div.style.width = that.div.clientWidth + left + "px";
              } else if (dragType.indexOf("leftTop") > -1) {
                let left = origin.clientX - e.clientX;
                let widthPerHeight = that.div.clientWidth / that.div.clientHeight;
                that.div.style.top = parseInt(that.div.style.top) - (left / widthPerHeight) + "px";
                that.div.style.height = that.div.clientHeight + (left / widthPerHeight) + "px";
                that.div.style.width = that.div.clientWidth + (left) + "px";
                that.div.style.left = parseInt(that.div.style.left) - (left) + "px";
              } else if (dragType.indexOf("leftBot") > -1) {
                let left = origin.clientX - e.clientX;
                let widthPerHeight = that.div.clientWidth / that.div.clientHeight;
                that.div.style.height = that.div.clientHeight + (left / widthPerHeight) + "px";
                that.div.style.width = that.div.clientWidth + (left) + "px";
                that.div.style.left = parseInt(that.div.style.left) - (left) + "px";
              } else if (dragType.indexOf("rightTop") > -1) {
                let left = e.clientX - origin.clientX;
                let widthPerHeight = that.div.clientWidth / that.div.clientHeight;
                that.div.style.height = that.div.clientHeight + (left / widthPerHeight) + "px";
                that.div.style.width = that.div.clientWidth + (left) + "px";
                that.div.style.top = parseInt(that.div.style.top) - (left / widthPerHeight) + "px";
              }
              that.set("origin", e);
              that.position = that.getProjection().fromDivPixelToLatLng({
                x: parseInt(that.div.style.left),
                y: parseInt(that.div.style.top)
              });
              let pos = that.getProjection().fromLatLngToDivPixel(that.position);
              let x = pos.x + that.div.clientWidth;
              let y = pos.y + that.div.clientHeight;
              let bottom = that.getProjection().fromDivPixelToLatLng({ x, y });
              that.position1 = bottom;
              that.draw(true);
            });
        });

        mapApi.event.addDomListener(item, "mouseup", function(e) {
          that.isScaling = false;
          mapInstance.set("draggable", true);
          that.div.draggable = true;
          mapApi.event.removeListener(that.scaleHandler);
        });

      });
      mapApi.event.addDomListener(this.get("map").getDiv(),
        "mouseleave",
        function() {
          mapApi.event.trigger(this.div, "mouseup");
          arr.forEach(item => {
            mapApi.event.trigger(item, "mouseup");
          });
        }
      );

      mapApi.event.addDomListener(this.get("map").getDiv(),
        "mouseup",
        function() {
          that.isScaling = false;
          mapInstance.set("draggable", true);
          that.div.draggable = true;
          that.div.style.cursor = "default";
          that.moving = false;
          mapApi.event.removeListener(that.moveHandler);
          mapApi.event.removeListener(that.scaleHandler);
        }
      );


      mapApi.event.addDomListener(this.div,
        "mousedown",
        function(e) {
          that.moving = true;
          that.div.style.cursor = "move";
          mapInstance.set("draggable", false);
          that.set("origin", e);

          that.moveHandler = mapApi.event.addDomListener(mapInstance.getDiv(),
            "mousemove",
            function(e) {
              if (!that.moving) return;
              let origin = that.get("origin");
              let left = origin.clientX - e.clientX;
              let top = origin.clientY - e.clientY;
              let pos = that.getProjection().fromLatLngToDivPixel(that.position);
              let latLng = that.getProjection().fromDivPixelToLatLng(new mapApi.Point(pos.x - left, pos.y - top));
              that.set("origin", e);
              that.set("position", latLng);
              let pos1 = { x: pos.x + that.div.clientWidth, y: pos.y + that.div.clientHeight };
              let sw = that.getProjection().fromDivPixelToLatLng(pos1);
              that.position1 = sw;
              that.draw();
            });
        }
      );

      mapApi.event.addDomListener(this.div, "mouseup", function() {
        that.div.draggable = true;
        mapInstance.set("draggable", true);
        that.moving = false;
        that.div.style.cursor = "default";
        that.isScaling = false;
        mapApi.event.removeListener(that.moveHandler);
        mapApi.event.removeListener(that.scaleHandler);
      });

      mapInstance.addListener("zoom_changed", () => {
        that.div.style.visibility = "hidden";
        setTimeout(() => {
          that.draw(true);
          that.div.style.visibility = "visible";
        }, 500);
      });

      this.set("this.div", this.div);
      this.getPanes().overlayMouseTarget.appendChild(this.div);
      this.getPanes().overlayMouseTarget.style['zIndex'] = 0

      if (!that.position1) {
        that.div.style.width = "100px";
        setTimeout(() => {
          if(!that.map) return
          let pos = that.getProjection().fromLatLngToDivPixel(that.position);
          let pos1 = { x: pos.x + that.div.clientWidth, y: pos.y + that.div.clientHeight };
          let sw = that.getProjection().fromDivPixelToLatLng(pos1);
          that.position1 = sw;
          mapInstance.setCenter(middle(that.position, that.position1));
          that.draw();
        }, 500);
      } else {
        if (curFloor && curFloor.northWestLongitude && curFloor.southEastLatitude && curFloor.northWestLatitude && curFloor.southEastLongitude) {
          let d = getDistance(new mapApi.LatLng(curFloor.northWestLatitude, curFloor.northWestLongitude), new mapApi.LatLng(curFloor.southEastLatitude, curFloor.southEastLongitude));
          let keys = Object.keys(radiusZoomLevel).reverse();
          let value = 100000000000;
          let index = 0;
          for (let i = 0; i < keys.length; i++) {
            let v = Math.abs(radiusZoomLevel[keys[i]] - d);
            if (v < value) {
              value = v;
              index = keys[i];
            }
          }
          mapInstance.setZoom(Number(index))
          setTimeout(() => {
            mapInstance.setZoom(Number(index) - 1)
            setLoading(false)
          }, 500);
        } else if (curFloor) {
          setTimeout(() => {
            mapInstance.setZoom(21)
            setLoading(false)
          }, 500);
        }
        mapInstance.setCenter(middle(that.position, that.position1));
      }
    };

    overlay.setMap(mapInstance);
  };

  const initDrawPolygon = () => {
    if (drawingManager) {
      drawingManager.setDrawingMode(null);
      drawingManager.setMap(null);
      drawingManager = null;
    }
    let polyOptions = {
      strokeWeight: 2,
      fillOpacity: 0,
      editable: true,
      draggable: true
    };
    drawingManager = mapApi.drawing.DrawingManager && new mapApi.drawing.DrawingManager({
      drawingModes: [
        mapApi.drawing.OverlayType.POLYGON,
        mapApi.drawing.OverlayType.RECTANGLE
      ],
      drawingControlOptions: {
        position: mapApi.ControlPosition.TOP_CENTER,
        drawingModes: [
          mapApi.drawing.OverlayType.POLYGON
        ]
      },
      polylineOptions: {
        editable: true,
        draggable: true
      },
      polygonOptions: polyOptions,
      map: mapInstance
    });

    mapApi.event.addListener(drawingManager, "drawingmode_changed", (e) => {
      let mode = drawingManager.getDrawingMode()
      mode ? setDrawing(true) : setDrawing(false)
    });

    mapApi.event.addListener(drawingManager, "overlaycomplete", function(e) {
      drawingManager.setDrawingMode(null);
      if (e.type !== mapApi.drawing.OverlayType.MARKER) {
        // Switch back to non-drawing mode after drawing a shape.
        let newShape = e.overlay;
        newShape.type = e.type;
        selectShape && selectShape.setMap(null);
        selectShape = newShape;
        selectShape.setDraggable(false)
        selectShape.setEditable(false)
      }
    });
  };

  useEffect(() => {
    if(!overlay) return
    if(drawing) {
      overlay.getPanes().overlayMouseTarget.childNodes.length > 0 && overlay.getPanes().overlayMouseTarget.removeChild(overlay.div);
      overlay.getPanes().overlayLayer.appendChild(overlay.div);
      selectShape && selectShape.setDraggable(true)
      selectShape && selectShape.setEditable(true)
      overlay.div.childNodes.forEach(item => {
        if(item.nodeName === 'SPAN')  item.style.visibility = "hidden"
      })
    } else {
      selectShape && selectShape.setDraggable(false)
      selectShape && selectShape.setEditable(false)
      overlay.getPanes().overlayLayer.childNodes.length > 0 && overlay.getPanes().overlayLayer.removeChild(overlay.div);
      overlay.getPanes().overlayMouseTarget.appendChild(overlay.div);
      overlay.div.childNodes.forEach(item => {
        if(item.nodeName === 'SPAN') item.style.visibility = "visible"
      })
    }
  }, [drawing])

  useEffect(() => {
    if (mapApi && mapInstance && img) {
      setTimeout(() => addImage(), 1500);
    }
    if (mapApi && mapInstance && !img) {
      overlay && overlay.setMap(null);
      overlay = null;
    }
    if (curFloor?.rotation && mapApi && mapInstance) {
      setRotate(curFloor.rotation);
    }
    if (mapApi && mapInstance && curFloor?.altitude){
      setAltitude(curFloor.altitude);
    }
    if (mapApi && mapInstance && !drawingManager) {
      initDrawPolygon();
    }
    if (mapApi && mapInstance && curFloor?.polygon) {
      initPolygon();
    }
  }, [mapApi, mapInstance, img, curFloor]);

  return (
    <>
      <div className={`floor-plan position-relative ${user?.role === "Root" ? "" : "hidden-map"}`} style={{ height: 450, width: "100%" }}>
        <Form.Check className="position-absolute z-50 text-light" style={{left: '5px'}}  checked={drawing}
                     onChange={e => setDrawing(e.target.checked)}
                     label="Drawing boundaries" />
        {loading && <div className="w-100 z-50 bg-light position-absolute h-100 align-items-center justify-content-center d-flex"><Spinner animation="border" /></div>}
        <GoogleMapReact
          id={"map-google"}
          options={getMapOptions}
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"]
          }}
          defaultCenter={{
            lat: 51.5075749806687,
            lng: Number(curFloor?.northWestLongitude) || -0.1258434166277178
          }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {mapInstance && mapApi && user?.role === "Root" &&
          <SearchBox ref={searchBox} map={mapInstance} mapApi={mapApi} />}
        </GoogleMapReact>
      </div>
      <div className="d-flex align-items-center mt-4">
        <div>lat(north-west): <input readOnly={user?.role !== "Root"} type={"number"} value={position && position.lat() || 0}
                                onChange={e => changeOverlayPosition("position", "lat", e.target.value)}
                                     className="table-search debounceInput-search text-gray me-2" /></div>
        <div>lng(north-west): <input readOnly={user?.role !== "Root"} type={"number"} value={position && position.lng() || 0}
                                onChange={e => changeOverlayPosition("position", "lng", e.target.value)}
                                     className="table-search debounceInput-search text-gray me-2" /></div>
      </div>
      <div className="d-flex align-items-center mt-2">
        <div>lat(south-east): <input readOnly={user?.role !== "Root"} type={"number"}
                                value={position1 && position1.lat() || 0}
                                onChange={e => changeOverlayPosition("position1", "lat", e.target.value)}
                                     className="table-search debounceInput-search text-gray me-2" /></div>
          <div>lng(south-east): <input readOnly={user?.role !== "Root"} type={"number"}
                                value={position1 && position1.lng() || 0}
                                onChange={e => changeOverlayPosition("position1", "lng", e.target.value)}
                                       className="table-search debounceInput-search text-gray me-2" /></div>
      </div>
      <div className="d-flex align-items-center mt-2">
      <div>altitude: <input readOnly={user?.role !== "Root"} type={"number"}
                                value={altitude || 0}
                                onChange={e => setAltitude(Number(e.target.value))}
                                     className="table-search debounceInput-search text-gray me-2" /></div>
      </div>
      <div className="d-flex align-items-center mt-2">
        opacity: <Form.Range style={{ width: "100px" }} className="me-1 ms-1" value={opacity}
                             onChange={e => setOpacity(Number(e.target.value))} /> <label
        style={{ width: "50px" }}>{opacity} %</label>
        rotate: <NumericInput readOnly={user?.role !== "Root"} style={{
        input: {
          color: "#a9acb3"
        }
      }} className="table-search debounceInput-search" value={rotate} min={0} max={359} onChange={e => setRotate(e)} />
        {user?.role === "Root" && <div className="ms-2">
          <Knob
            size={60}
            degrees={360}
            min={0}
            max={360}
            value={rotate}
            color={true}
            onChange={(value) => {
              setRotate(value);
            }}
          />
        </div>}
      </div>
    </>
  );
};
export default Streetview = forwardRef(Streetview);
