import React, { useEffect, useState } from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { Image } from "react-feather";

const PositionsFloorplanSelector = (props) => {
    const { onSelect, right, floorplans, selectedPosition } = props;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [showing, setShowing] = useState(false);
    const [current, setCurrent] = useState(null);

    const onSearch = (search) => {
        setSearchString(search);
        var floor = floorplans.find((i) => { return i.id === selectedPosition.floorPlan.id });
        onSelect && onSelect(floor);
        setCurrent(floor);
    };

    useEffect(() => {

        if (floorplans && onSelect) {
            setData(floorplans);
            onSelect(floorplans[0]);
        }
    }, [floorplans]);

    useEffect(() => {
        if (selectedPosition) {
            if (current === null || current.id !== selectedPosition.floorPlan?.id) {
                onSearch(selectedPosition.floorPlan.floorPlanName);
            }
        }
    }, [selectedPosition]);


    return (
        <>
            <div style={{ right: right ? '54px' : '', margin: '10px' }} className={`z-49 mobile-style position-absolute ${!right ? `left-0` : 'right-0'} top-0`}>
                <DebounceInput
                    className="hashtag-input text-gray w-100"
                    style={{ border: "1px solid #7f838e", padding: "4px 8px", fontSize: "12px", height: '32px' }}
                    minLength={0}
                    debounceTimeout={300}
                    value={searchString}
                    placeholder="Search floorplans"
                    onClick={e => e.stopPropagation()}
                    onFocus={(e) => {
                        setShowing(true);
                    }}
                // onChange={event => {
                //     onSearch(event.target.value);
                // }}
                />

                {loading &&
                    <div className="w-100 bg-light d-flex justify-content-center p-2"><Spinner animation="border" /></div>}
                <div style={{ maxHeight: "500px" }} className="overflow-auto">
                    {showing && <ListGroup>
                        {data && data.length > 0 && data.map((item, index) => <ListGroup.Item
                            onClick={(event) => {
                                event.stopPropagation();
                                setSearchString(item.floorPlanName);
                                onSelect && onSelect(item);
                                setShowing(false);
                                setCurrent(item);
                            }} key={index}
                            className={`cursor-pointer list-group-hover ${current && item.id === current.id ? "bg-primary" : ""}`}>
                            <Image size={14} />
                            <span className="ms-1">{item.floorPlanName}</span>
                        </ListGroup.Item>)}
                        {/* {props.showMap && <ListGroup.Item
                            onClick={(event) => {
                                event.stopPropagation();
                                setSearchString('Map');
                                props.onSelect && props.onSelect(null);
                                setShowing(false);
                                setCurrent(null);
                            }}
                            className={`cursor-pointer list-group-hover ${!current ? "bg-primary" : ""}`}>
                            <Map size={14} />
                            <span className="ms-1">Map</span>
                        </ListGroup.Item>} */}
                    </ListGroup>}
                </div>
            </div>
        </>
    );
};

export default PositionsFloorplanSelector;
