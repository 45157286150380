import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import GoogleMapReact from "google-map-react";
import { Col, Row } from "react-bootstrap";
import { globalConfig } from "../../config";
import FloorplanSelector from "../readers/FloorplanSelector";
import api from "../../api";
import { getDistance, getUrl, middle, radiusZoomLevel, resArr, updateMarker } from "../../utils/staticMethods";
import NearByAssets from "../../components/mapComponents/NearByAssets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HashTagFilter from "../../components/mapComponents/HashTagFilter";
import useAuth from "../../hooks/useAuth";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
// import GroupSelector from "../../components/groupSelector/GroupSelector";
import axios from "axios";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import circle from "../../assets/img/clusterImg/m1.png";
import circlePng from "../../assets/img/clusterImg/m1.png";
import { imageSet } from "../icons/AssetIcon/IconList";
import InfoWindow from "../../components/mapComponents/InfoWindow";
import GroupSelectorFloorplan from "../../components/groupSelector/GroupSelectorFloorplan";
import MapDetail from "../../components/detail/MapDetail";
import MarkerModal from "../../components/mapComponents/MarkerModal";

let overlay1 = null;
let markerArr = [];
let markerCluster = null;
// let timer = null;

const thingsArr = {
    asset: "assets",
    person: "people",
    zone: "zones",
    assets: "assets",
    people: "people",
    zones: "zones"
};

const FloorplansView = () => {
    const limit = 450;

    const { user } = useAuth();

    const [curFloor, setCurFloor] = useState(null);
    const [floorplanImage, setFloorplanImage] = useState(null);
    const [mapInstance, setInstance] = useState();
    const [mapApi, setApi] = useState();

    const [showSearch, setShowSearch] = useState(true);
    const [nearByData, setNearByData] = useState([]);
    const [hashtag, setHashtag] = useState(null);

    const sourceRef = useRef(null);
    const [overlay, setOverlay] = useState(false);

    const [current, setCurrent] = useState(null);
    const [infoItem, setInfoItem] = useState([]);
    const [tableData, setTableData] = useState(null);

    const [following, setFollowing] = useState(false);

    const intervalNearbyRef = useRef(null);

    const getMapOptions = (maps) => {
        if (mapInstance && mapApi) return;
        return {
            mapId: "90f87356969d889c",
            fullscreenControl: true,
            mapTypeControl: false,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            // heading: (360 - curFloor?.rotation || 0),
            heading: 0,
            gestureHandling: "cooperative",
            // mapTypeControl: true,
            // streetViewControl: true,
            mapTypeControlOptions: {
                position: maps.ControlPosition.LEFT_BOTTOM
            }
        };
    };

    const apiHasLoaded = (map, maps) => {
        if (!map || !maps) return;
        setInstance(map);
        setApi(maps);
        window.map = map;
    };

    // const cancelOption = () => { };

    // const showOptions = () => { };

    const onSelectPlan = (selectedPlan) => {
        setCurFloor(selectedPlan);
        getFloorplanImage(selectedPlan.id)
    };

    const getFloorplanImage = (id) => {
        api.get(`files/floorplans/${id}`, {
            responseType: "arraybuffer"
        }).then(res => {
            let blob = new Blob([res.data], { type: "img/jpeg" });
            let url = (window.URL || window.webkitURL).createObjectURL(blob);
            setFloorplanImage(url);
        }).catch(() => setFloorplanImage(null));
    };

    const initFloorPlan = () => {
        if (mapApi && mapInstance && floorplanImage) {
            mapInstance.setOptions({ mapTypeControl: false, streetViewControl: false });
            mapInstance.setHeading(360 - curFloor?.rotation || 0);
            if (overlay1) {
                overlay1.setMap(null);
                overlay1 = null;
            }
            let northWest = new mapApi.LatLng(curFloor ? (Number(curFloor.northWestLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.northWestLongitude) || 0.1276) : 0.1276);
            let southEast = new mapApi.LatLng(curFloor ? (Number(curFloor.southEastLatitude || 51.5072)) : 51.5072, curFloor ? (Number(curFloor.southEastLongitude) || 0.1276) : 0.1276);
            overlay1 = new mapApi.OverlayView();
            overlay1.div = null;
            window.overlay = overlay1;
            overlay1.image = floorplanImage;
            overlay1.draw = function () {
                const overlayProjection = this.getProjection();
                const se = overlayProjection.fromLatLngToDivPixel(
                    southEast
                );
                const nw = overlayProjection.fromLatLngToDivPixel(
                    northWest
                );
                if (this.div) {
                    this.div.style.left = nw.x + "px";
                    this.div.style.top = nw.y + "px";
                    this.div.style.width = se.x - nw.x + "px";
                    this.div.style.height = se.y - nw.y + "px";
                }
            };
            overlay1.onRemove = function () {
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                }
            };
            overlay1.onAdd = function () {
                this.div = document.createElement("div");
                this.div.id = "whole-container";
                this.div.style.borderStyle = "none";
                this.div.style.borderWidth = "0px";
                this.div.style.position = "absolute";
                this.div.style.visibility = "visible";

                const img = document.createElement("img");

                img.src = this.image;
                img.style.width = "100%";
                img.style.height = "100%";
                img.style.position = "absolute";
                this.div.appendChild(img);
                const panes = this.getPanes();
                panes.overlayLayer.appendChild(this.div);
            };
            overlay1.setMap(mapInstance);
            let d = getDistance(northWest, southEast);
            let keys = Object.keys(radiusZoomLevel).reverse();
            let value = 1000000;
            let index = 0;
            for (let i = 0; i < keys.length; i++) {
                let v = Math.abs(radiusZoomLevel[keys[i]] - d);
                if (v < value) {
                    value = v;
                    index = keys[i];
                }
            }
            mapInstance.setZoom(Number(index));
            // defaultLevelRef.current = Number(index)
            mapInstance.panTo(middle(northWest, southEast));
            // setZoomLevel(Number(index));
            // zoomLevelRef.current = Number(index);
            // setCenter(middle(northWest, southEast));
            // centerRef.current = middle(northWest, southEast)
            // defaultCenterRef.current = centerRef.current
            // setTimeout(() => setLoadingPlan(false), 1000);
        }
    };

    useEffect(() => {
        setNearByData([]);
        // setOverlay(true);
        markerArr && markerArr.forEach(item => {
            item.setMap(null);
            item = null;
        });
        markerArr = [];
        if (markerCluster) {
            markerCluster.clearMarkers();
            markerCluster.setMap(null);
            markerCluster = null;
        }

    }, []);

    useEffect(() => {
        if (!mapInstance || !mapApi || !curFloor || !floorplanImage) return;
        initFloorPlan();
        setInfoItem([]);
        getNearBy();
        if (intervalNearbyRef.current) {
            clearInterval(intervalNearbyRef.current);
            intervalNearbyRef.current = null;
        }
        intervalNearbyRef.current = setInterval(() => {
            getNearBy();
        }, 5000);

        return (() => {
            if (intervalNearbyRef.current) {
                clearInterval(intervalNearbyRef.current);
                intervalNearbyRef.current = null;
            }
        });
    }, [mapInstance, mapApi, curFloor, floorplanImage, hashtag]);

    const getNearBy = async () => {
        console.log("getNearBy");
        if (!mapInstance.getCenter()) return;
        let count = [0, 0, 0];
        if (sourceRef.current) {
            sourceRef.current.cancel("request canceled");
        }

        // setNearByData([]);
        // count[0] = Number(totalHeader);
        // setOverlay(true);
        // markerArr && markerArr.forEach(item => {
        //     item.setMap(null);
        //     item = null;
        // });
        // markerArr = [];
        // if (markerCluster) {
        //     markerCluster.clearMarkers();
        //     markerCluster.setMap(null);
        //     markerCluster = null;
        // }

        sourceRef.current = axios.CancelToken.source();
        // const res = await api.get(`floorplans/nearby?radius=${radiusZoomLevel[Math.round(zoomLevelRef.current)]}&latitude=${mapInstance.getCenter().lat()}&longitude=${mapInstance.getCenter().lng()}&limit=${limit}`, {
        const res = await api.get(`floorplans/nearbyid?id=${curFloor.id}`, {
            params: {
                limit: limit,
                hashtag: hashtag
            },
            source: sourceRef.current
        });

        //if (!firstLoadRef.current) return
        const totalHeader = res.headers["x-total-count"];
        console.log("getNearBy2", { totalHeader, data: res.data });
        if (Number(totalHeader) >= limit) {
            setNearByData([])
            count[0] = Number(totalHeader);
            setOverlay(true);
            markerArr && markerArr.forEach(item => {
                item.setMap(null);
                item = null;
            });
            markerArr = [];
            if (markerCluster) {
                markerCluster.clearMarkers();
                markerCluster.setMap(null);
                markerCluster = null;
            }
        } else {
            setNearByData(res.data);
            setOverlay(false);
            let arr = res.data;
            if (arr.length > 0) {
                let infoArr = infoItem.concat([]);
                updateMarker(arr, markerArr, infoArr, mapInstance, mapApi);
                let removeArr = resArr(markerArr, arr);
                removeMaker(removeArr, infoArr)
                let newArr = resArr(arr, markerArr);
                showMarker(newArr, infoArr);
                setInfoItem(infoArr);
                console.log("getNearBy3", { arr, markerArr, infoArr, newArr });
            }
            else {
                markerArr && markerArr.forEach(item => {
                    item.setMap(null);
                    item = null;
                });
                markerArr = [];
                setInfoItem([]);
            }

        }
        // timer = null;
    };

    const removeMaker = (removeArr, infoArr) => {
        removeArr.forEach(item => {
            let index = infoArr.findIndex(f => f.lastPosition.id === item.id)
            if (index > -1) {
                infoArr[index].hide = true
            }
            for (let i = 0; i < markerArr.length; i++) {
                if (markerArr[i].idsArr.includes(item.id)) {
                    if (markerArr[i].idsArr.length === 1) {
                        markerArr[i].setMap && markerArr[i].setMap(null);
                        markerCluster && markerCluster.removeMarker(markerArr[i]);
                        markerArr.splice(i, 1)
                        break
                    } else if (markerArr[i].idsArr.length > 1) {
                        markerArr[i].idsArr = markerArr[i].idsArr.filter(arrItem => arrItem !== item.id)
                        markerArr[i].ids = markerArr[i].ids.filter(arrItem => arrItem.id !== item.id)
                        markerArr[i].id = markerArr[i].idsArr[0]
                        markerArr[i].setMap && markerArr[i].setMap(mapInstance);
                        if (markerArr[i].idsArr.length === 1) {
                            markerArr[i].setIcon({ ...markerArr[i].icon, scaledSize: new mapApi.Size(24, 24), url: imageSet[getUrl(markerArr[i].ids[0].icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(markerArr[i].ids[0].icon)]).default : defaultImg });
                            markerArr[i].setLabel({ className: "marker-position-bottom", color: "#000000", fontWeight: "bold", text: markerArr[i].ids[0].compoundName })
                            mapApi.event.clearListeners(markerArr[i], "click");
                            // eslint-disable-next-line no-loop-func
                            markerArr[i].addListener("click", function (e) {
                                e.domEvent.stopImmediatePropagation();
                                // setCenter(markerArr[i].position);
                                //centerRef.current = markerArr[i].position;
                                mapInstance.panTo(markerArr[i].position);
                                if (mapInstance.getZoom() < 18) {
                                    mapInstance.setZoom(18)
                                }
                                let cur = this.ids[0];
                                cur.lastPosition = this.ids[0];
                                cur.type = this.type;
                                // currentRef.current = cur;
                                setCurrent(cur);
                                setInfoItem(pre => {
                                    let obj = { lastPosition: this.ids[0] };
                                    if (pre.length === 0) {
                                        return [obj];
                                    }
                                    let arr = pre.concat([])
                                    let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
                                    if (filter.length === pre.length) {
                                        arr.push(obj);
                                        return arr;
                                    } else {
                                        return filter;
                                    }
                                });
                            });
                        }
                        break
                    }
                }
            }
        });
    }

    const showMarker = (newArr, infoArr) => {
        newArr.forEach(item => {
            let filter = markerArr.filter(markerMe => (markerMe.type !== "zone" && markerMe.type !== "zones" && markerMe.position.lat().toFixed(5) === Number(item.latitude).toFixed(5) && markerMe.position.lng().toFixed(5) === Number(item.longitude).toFixed(5)));
            if (filter && filter.length > 0 && item.type !== "zone" && item.type !== "zones") {
                if (item.type === "asset") filter[0].asset++;
                if (item.type === "person") filter[0].person++;
                filter[0].ids.push(item);
                filter[0].idsArr.push(item.id);
                filter[0].label.text = ' ';
                filter[0].label.className = "font-bold";
                filter[0].icon.url = circlePng;
                filter[0].icon.scaledSize = new mapApi.Size(35, 35);
                mapApi.event.clearListeners(filter[0], "click");
                filter[0].addListener("click", (e) => {
                    e.domEvent.stopImmediatePropagation();
                    // setCenter(filter[0].position);
                    // centerRef.current = filter[0].position
                    setTableData(filter[0].ids)
                });
                filter[0].setMap(mapInstance)
            } else if ((item.type === "zone" || item.type === "zones") && item.latitude && item.longitude) {
                let m = new mapApi.Marker({
                    id: item.id,
                    type: "zones",
                    asset: item.type === "asset" ? 1 : 0,
                    person: item.type === "person" ? 1 : 0,
                    zone: item.type === "zone" ? 1 : 0,
                    ids: [item],
                    idsArr: [item.id],
                    position: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
                    label: {
                        text: item.compoundName,
                        color: "#FFFFFF",
                        className: "marker-position-top bg-danger ps-1 pe-1 radius-3",
                        fontWeight: 'bold'
                    },
                    icon: {
                        url: imageSet[getUrl(item.icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
                        anchor: new mapApi.Point(12, 12),
                        scaledSize: new mapApi.Size(24, 24)
                    },
                    anchor: new mapApi.Point(14, 43),
                    map: mapInstance
                });
                m.addListener("click", function (e) {
                    e.domEvent.stopImmediatePropagation();
                    let cur = JSON.parse(JSON.stringify(m.ids[0]));
                    cur.type = m.type;
                    // currentRef.current = cur;
                    setCurrent(cur);
                    mapInstance.setZoom(21);
                    // setCenter(m.position);
                    // centerRef.current = m.position;
                    mapInstance.panTo(m.position);
                });
                markerArr.push(m);
            } else if (item.latitude && item.longitude) {
                let markerFilter = markerArr && markerArr.filter(markerMe => markerMe?.type === "zones" && markerMe?.position.lat().toFixed(5) === Number(item?.latitude).toFixed(5) && markerMe?.position.lng().toFixed(5) === Number(item?.longitude).toFixed(5));
                let m = new mapApi.Marker({
                    id: item.id,
                    asset: item.type === "asset" ? 1 : 0,
                    person: item.type === "person" ? 1 : 0,
                    zone: item.type === "zone" ? 1 : 0,
                    ids: [item],
                    idsArr: [item.id],
                    position: {
                        lat: Number(item.latitude || item.lastPosition?.latitude),
                        lng: Number(item.longitude || item.lastPosition?.longitude)
                    },
                    label: {
                        text: item.compoundName,
                        color: "#FFFFFF",
                        className: "marker-position-bottom bg-primary ps-1 pe-1 radius-3",
                        fontWeight: 'bold'
                    },
                    type: thingsArr[item.type],
                    icon: {
                        url: imageSet[getUrl(item.icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
                        scaledSize: new mapApi.Size(24, 24),
                        labelOrigin: new mapApi.Point(12, markerFilter && markerFilter.length > 0 ? -48 : 12),
                        anchor: new mapApi.Point(12, 12),
                    },
                    anchor: new mapApi.Point(14, 43),
                    map: mapInstance
                });
                let filter = infoArr.filter(f => f.lastPosition.id === item.id)
                if (filter.length > 0) {
                    filter[0].lastPosition.latitude = item.latitude
                    filter[0].lastPosition.longitude = item.longitude
                    filter[0].hide = false
                }
                m.addListener("click", function (e) {
                    e.domEvent.stopImmediatePropagation();
                    // setCenter(m.position);
                    // centerRef.current = m.position;
                    mapInstance.panTo(m.position);
                    if (mapInstance.getZoom() < 18) {
                        mapInstance.setZoom(18)
                    }
                    let cur = this.ids[0];
                    cur.lastPosition = this.ids[0];
                    cur.type = m.type;
                    // currentRef.current = cur;
                    setCurrent(cur);
                    setInfoItem(pre => {
                        let obj = { lastPosition: this.ids[0] };
                        if (pre.length === 0) {
                            return [obj];
                        }
                        let arr = pre.concat([])
                        let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
                        if (filter.length === pre.length) {
                            arr.push(obj);
                            return arr;
                        } else {
                            return filter;
                        }
                    });
                });
                markerArr.push(m);
                if (
                    // m.id !== current.id &&
                    markerCluster
                ) {
                    markerCluster.addMarker(m);
                }
            }
        });

        if (!markerCluster && markerArr.filter(item => (item.type !== "zone" && item.type !== 'zones')).length > 1) {
            markerCluster = new MarkerClusterer({
                map: mapInstance, markers: markerArr.filter(item => (item.type !== "zone" && item.type !== "zones")), renderer: {
                    render({ position }) {
                        return new mapApi.Marker({
                            label: "",
                            position,
                            icon: {
                                url: circle,
                                scaledSize: new mapApi.Size(36, 36),
                                anchor: new mapApi.Point(18, 18),
                            },
                        });
                    }
                }, algorithm: new GridAlgorithm({
                    gridSize: 7,
                    maxZoom: 18,
                }), onClusterClick(e, cluster) {
                    mapInstance.setZoom(18)
                    mapInstance.setCenter(cluster.marker.position)
                    // centerRef.current = cluster.marker.position
                }
            });
        }
    };

    const clickTarget = (item) => {
        let arr = infoItem.concat([]);
        arr.forEach(i => {
            if (i.lastPosition.id === item.lastPosition.id) {
                i.active = true;
            } else {
                i.active = false;
            }
        });
        setInfoItem(arr);
    };

    const onClose = (e, item) => {
        e.stopPropagation();
        setInfoItem(pre => {
            let filter = pre.filter(i => i.lastPosition.id !== item.lastPosition.id);
            return filter;
        });
    };

    const onSelect = (e) => {
        // currentRef.current = e;
        setCurrent(e);
        if ((e.type === "assets" || e.type === "people") && e.lastPosition && e.lastPosition.latitude && e.lastPosition.longitude && mapApi && mapInstance) {
            mapInstance.panTo({ lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) });
            // setCenter({ lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) });
            //   centerRef.current = { lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) };
            mapInstance.setZoom(18);
        } else if (e.type === "zones" && e.longitude && e.latitude) {
            mapInstance.panTo({ lat: Number(e.latitude), lng: Number(e.longitude) });
            // setCenter({ lat: Number(e.latitude), lng: Number(e.longitude) });
            //   centerRef.current = { lat: Number(e.latitude), lng: Number(e.longitude) };
            mapInstance.setZoom(18);
        }
        onSetFilter(null)
    };

    const handleAll = () => {
        if (infoItem?.length === 0) {
            let arr = markerArr.filter(item => (item.ids.length === 1 && item.type !== "zones" && item.type !== "zone"));
            let newArr = arr.map(item => {
                let obj = { lastPosition: item.ids[0] };
                return obj;
            });
            setInfoItem(newArr);
        } else {
            setInfoItem([]);
        }
    };

    const onSetFilter = (tags) => {
        // hashtagRef.current = tags
        setHashtag(tags)
        // if (!tags && currentRef.current) {
        //   firstLoadRef.current = true
        //   setFirstLoad(true)
        //   return
        // }
        // api.get(`maps/defaultitem`, {
        //   params: {
        //     hashtag: hashtagRef.current
        //   }
        // }).then(res1 => {
        //   let c = res1.data;
        //   if (!c) {
        //     currentRef.current = null;
        //     setCurrent(null)
        //     mapInstance && mapInstance.setZoom(0)
        //     markerArr.forEach(item => item.setMap && item.setMap(null))
        //     markerArr = []
        //     markerCluster && markerCluster.clearMarkers() && markerCluster.setMap(null)
        //     markerCluster = null
        //     firstLoadRef.current = false
        //     setFirstLoad(false)
        //     setNearByData([]);
        //     setInfoItem([])
        //     return;
        //   }
        //   c.type = thingsArr[c.type];
        //   api.get(`${c.type}/${c.id}`).then(res => {
        //     if (tags) {
        //       let cur = res.data;
        //       cur.type = c.type;
        //       currentRef.current = cur;
        //       setCurrent(cur);
        //       setCenter({ lat: Number(c.latitude), lng: Number(c.longitude) });
        //       centerRef.current = { lat: Number(c.latitude), lng: Number(c.longitude) };
        //       mapInstance && mapInstance.setZoom(18)
        //       mapInstance && mapInstance.panTo(centerRef.current)
        //     } else {
        //       mapInstance && mapInstance.setZoom(defaultLevelRef.current)
        //       mapInstance && mapInstance.panTo(defaultCenterRef.current)
        //     }
        //     firstLoadRef.current = true
        //     setFirstLoad(true)
        //   });
        // });
    }

    return (
        <>
            <Helmet defer={false} title={curFloor?.floorPlanName || "Floorplan"} />
            <h1 className="h3 mb-3">{curFloor?.floorPlanName}</h1>
            <Row>
                <Col>
                    {/* {loadingPlan &&
                        <div className="position-absolute w-100 h-100 bg-light z-50 d-flex align-items-center justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    } */}
                    {overlay &&
                        <div className="p-3 ps-2 bg-light text-warning">Too many items to show. Please zoom or search to narrow
                            results</div>
                    }
                    {!current &&
                        <div className="p-3 ps-2 bg-light text-warning">No relevant assets/people to show, please select other hashtag</div>
                    }
                    {current && !overlay ? (<MapDetail fromFloorplan profile={current} following={following} onChangeToggle={(e) => {
                        // follow.current = e.target.checked;
                        setFollowing(e.target.checked);
                    }} />) : null
                    }

                    {
                        showSearch && mapApi &&
                        <div className="position-absolute mobile-style z-100">
                            <GroupSelectorFloorplan onSelect={onSelect} floorplan={curFloor} />
                        </div>
                    }

                    {
                        user?.role !== 'Root' && mapApi &&
                        <div className="position-absolute cursor-pointer z-50 mt-5 d-flex align-items-center" >
                            <NearByAssets result={nearByData} />
                            <div onClick={() => handleAll()} className="cursor-pointer z-50 m-1">
                                <FontAwesomeIcon title={"All labels"} className="text-white bg-black mx-1"
                                    icon={faWindowMaximize} size={"lg"} color={"#293042"} fixedWidth />
                            </div>
                            <HashTagFilter onSetFilter={onSetFilter} showTags={hashtag} />
                        </div>
                    }
                    <div style={{ height: "70vh", width: "100%" }} className={`position-relative ${curFloor ? "hidden-map" : ""}`}>
                        <div className={`reader-floorplan-select z-49`}>
                            <FloorplanSelector asset={""} showDefault onSelect={onSelectPlan} />
                        </div>
                        {curFloor &&
                            <GoogleMapReact
                                options={getMapOptions}
                                bootstrapURLKeys={{
                                    key: globalConfig.googleMapKey,
                                    libraries: ["places", "geometry", "drawing", "visualization"]
                                }}
                                onClick={(ev) => {
                                    //infoRef.current && infoRef.current.close();
                                }}
                                defaultZoom={1}
                                defaultCenter={[
                                    51.5288684709715,
                                    -0.10159865243033028
                                ]}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                            >
                                {
                                    infoItem &&
                                    infoItem.map(item =>
                                        <InfoWindow key={item.lastPosition.id} clickTarget={clickTarget}
                                            lat={item?.lastPosition.latitude}
                                            lng={item?.lastPosition.longitude} show={item}
                                            onClose={(e) => onClose(e, item)} />
                                    )
                                }
                            </GoogleMapReact>
                        }
                    </div>
                </Col>
            </Row >
            <MarkerModal tableData={tableData} setTableData={setTableData} />
        </>
    );
};

export default FloorplansView;
